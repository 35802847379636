;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4182fe491235a415aa7e8b34770790858f412d21"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/hcp";import * as Sentry from '@sentry/nextjs';
import type { BrowserOptions } from '@sentry/nextjs';
import type { Client } from '@sentry/types';

import sharedSentryOptions from './shared-sentry-options';

export const initSentry = (
    additionalOptions: BrowserOptions
): Client | undefined =>
    Sentry.init({
        ...sharedSentryOptions,
        ...additionalOptions,
    });
